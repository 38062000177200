import React, { useState } from "react";
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Button,
    CollectionPreferences,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter
} from '@amzn/awsui-components-react/polaris';
import EmptyState from "./EmptyState";
import isEqual from "lodash.isequal";
import { COLLECTION_PREFERENCES, PAGINATION_LABELS } from "../util/configDetailCollection";

export default function DetailCollection(props) {
    var details = props.details;
    var columnDefinitions = props.columnDefinitions;
    var editButton = props.editButton;
    var editControls = props.editControls;
    var editFooter = props.editFooter;

    var columnIds = columnDefinitions.map(column => {
        return column.id;
    });

    var [preferences, setPreferences] = useState({
        pageSize: 10,
        visibleContent: columnIds
    });

    if (!isEqual(columnIds, preferences.visibleContent)) {
        setPreferences({
            pageSize: preferences.pageSize,
            visibleContent: columnIds
        });
    }

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        details,
        {
            filtering: {
                fields: preferences.visibleContent,
                empty: (
                    <EmptyState
                        title="No details"
                        subtitle="No details to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {}
        }
    );

    return (
        <SpaceBetween direction="vertical" size="xs">
            <Table
                {...collectionProps}
                header={
                    <Header
                        counter={items.length}
                        actions={editButton}
                    >
                        Promotions
                    </Header>
                }
                footer={editFooter}
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                items={items}
                pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_LABELS} />}
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={<div>{`${filteredItemsCount} ${filteredItemsCount > 1 ? "matches" : "match"}`}</div>}
                        filteringAriaLabel="Filter instances"
                        filteringPlaceholder="Search list"
                    />
                }
                preferences={
                    <CollectionPreferences id="collectionPrefs"
                                           {...COLLECTION_PREFERENCES}
                                           preferences={preferences}
                                           onConfirm={({ detail }) => setPreferences(detail)}
                    />
                }
            />
            {editControls}
        </SpaceBetween>
    );
}

export const COLUMN_DEFINITIONS = [
    {
        id: "Name",
        header: "Name",
        cell: e => e.Name,
        sortingField: "Name"
    },
    {
        id: "StartDate",
        header: "Start Date",
        cell: e => e.StartDate,
        sortingField: "StartDate"
    },
    {
        id: "EndDate",
        header: "End Date",
        cell: e => e.EndDate,
        sortingField: "EndDate"
    },
    {
        id: "Type",
        header: "Type",
        cell: e => e.Type,
        sortingField: "Type"
    },
    {
        id: "Status",
        header: "Status",
        cell: e => e.Status,
        sortingField: "Status"
    }
];

export const PAGINATION_LABELS = {
    nextPageLabel: "Next page",
    previousPageLabel: "Previous page",
    pageLabel: pageNumber =>
        `Page ${pageNumber} of all pages`
};

export const COLLECTION_PREFERENCES = {
    title: "Preferences",
    confirmLabel: "Confirm",
    cancelLabel: "Cancel",
    pageSizePreference: {
        title: "Select page size",
        options: [
            { value: 10, label: "10 flyers" },
            { value: 20, label: "20 flyers" }
        ]
    }
};
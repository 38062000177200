import React, { Component } from 'react';
import queryString from 'query-string'
import HomeTable from "./HomeTable";
import FlyerSearch from "./FlyerSearch";
import { Redirect } from "react-router-dom";
import { Button, Container, Header, SpaceBetween } from "@amzn/awsui-components-react/polaris";

export default class Search extends Component {
    componentDidMount() {
        let param = queryString.parse(this.props.location.search).q === "2019" ? "2019" : "";
        this.props.getFlyers("search", param);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            let param = queryString.parse(this.props.location.search).q === "2019" ? "2019" : "";
            this.props.getFlyers("search", param);
        }
    }

    render() {
        if (!this.props.location.search) {
            return <Redirect to="/"/>
        }
        let query = queryString.parse(this.props.location.search).q;
        return (
            <Container>
                <SpaceBetween direction="vertical" size="xs">
                    <Header
                        actions={
                            <SpaceBetween direction="horizontal" size="m">
                                <FlyerSearch value={query} history={this.props.history}/>
                                <Button onClick={() => {
                                    this.props.history.push("/");
                                }}>
                                    Return to Home
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        Previous Flyer Search
                    </Header>
                    <HomeTable {...this.props} title={`Showing Flyer Results for "${query}"`}/>
                </SpaceBetween>
            </Container>
        )
    }
}
import React from "react";
import { Amplify } from "aws-amplify";
import ReactDOM from "react-dom";
import App from "./App";

const callbackUrl = window.location.origin;
const awsRegion = 'us-west-2';
const config = {
    oauth: {
        domain:  'wanlesss-fdget-ui.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: callbackUrl,
        redirectSignOut: callbackUrl,
        responseType: 'code'
    },
    // Cognito
    aws_cognito_region: awsRegion,
    aws_user_pools_id: "us-west-2_mQs2rDUDK",
    aws_user_pools_web_client_id: "50l7fp9133c435ru3g5fsmtjh6",

    aws_project_region: awsRegion,
}

console.log('Amplify config', config);
Amplify.configure(config);

ReactDOM.render(
    <App/>,
    document.getElementById("root")
);
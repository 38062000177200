
export const ACTION_OPTIONS = [
    { label: "Approve", value: "1" },
    { label: "Assign Reviewer", value: "2" },
    { label: "Comment", value: "3" },
    { label: "Other", value: "4" }
];

export const ASSIGNEE_OPTIONS = [
    { value: "user1" },
    { value: "user2" },
    { value: "user3" },
    { value: "admin0" }
];

export const REASON_OPTIONS = [
    { label: "Reason A", value: "1" },
    { label: "Reason B", value: "2" },
    { label: "Reason C", value: "2" }
];
import React, { Component } from "react";
import {
    Button,
    Form,
    FormField,
    Header,
    Icon,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import DetailTable from "./DetailTable";
import { parse } from "papaparse";
import cloneDeep from "lodash.clonedeep";

const DEFAULT_PARSER_CONFIG = {
    header: true,
    transform: value => value.trim()
}

function UploadedFile(props) {
    return (
        <SpaceBetween direction="horizontal" size="xs">
            <Icon
                name="status-positive"
                size="normal"
                variant="success"
            />
            <SpaceBetween>
                <span>File: {props.file.name}</span>
                <span>Size: {props.file.size} bytes</span>
                <span>Last Modified: {props.file.lastModifiedDate.toString()}</span>
            </SpaceBetween>
            <Button
                iconName="close"
                variant="icon"
                onClick={props.onClick}
            />
        </SpaceBetween>
    )
}

export default class FlyerFileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            errorMessage: null,
            details: [],
            metadata: props.metadata,
            handleBack: props.handleBack,
            handleSubmit: props.handleSubmit,
            editing: false
        };
        this.hiddenInputRef = React.createRef();
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.handleFlyerUpload = this.handleFlyerUpload.bind(this);
        this.handleParseSuccess = this.handleParseSuccess.bind(this);
        this.handleParseError = this.handleParseError.bind(this);
        this.reset = this.reset.bind(this);
    }

    reset() {
        this.setState({
            file: null,
            errorMessage: null,
            details: [],
            editing: false
        });
    }

    handleFileSelect(selectedFile) {
        this.reset();
        parse(selectedFile, {
            ...DEFAULT_PARSER_CONFIG,
            complete: this.handleParseSuccess,
            error: this.handleParseError,
            skipEmptyLines: true,
        });
    }

    handleParseSuccess(parseResult, file) {
        this.setState({
            file: file,
            details: parseResult.data
        });
    }

    handleParseError(parseError, file) {
        this.setState({ errorMessage: parseError});
    }

    handleFlyerUpload() {
        // Make api calls
        this.state.handleSubmit();
    }

    render() {
        let uploadedFile = this.state.file ? (
            <UploadedFile
                file={this.state.file}
                onClick={this.reset}
            />
        ) : null;

        let previewContent = this.state.file ? (
            <>
                <Header>Flyer Preview</Header>
                <DetailTable
                    preview={true}
                    editControlsEnabled={true}
                    metadata={this.state.metadata}
                    details={this.state.details}
                    editWatcher={(editStatus) => {
                        this.setState({ editing: editStatus });
                    }}
                    onConfirm={(component) => {
                        this.setState({
                            flyerMetadata: component.state.metadata,
                            details: component.state.modifiableDetails
                        });
                        component.setState({
                            originalDetails: cloneDeep(component.state.modifiableDetails),
                            editing: false
                        })
                    }}
                />
            </>
        ) : null;

        return (
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            onClick={this.state.handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            disabled={!this.state.file || this.state.editing}
                            onClick={this.handleFlyerUpload}
                            variant="primary"
                        >
                            <SpaceBetween direction="horizontal" size="xs">
                                <Icon name="upload" size="normal" variant="normal" />
                                Upload Flyer
                            </SpaceBetween>
                        </Button>
                    </SpaceBetween>
                }
                header={<Header>Flyer Upload</Header>}
            >
                <SpaceBetween size="s">
                    <FormField
                        label={"Select a flyer csv file to upload."}
                    >
                        <input
                            ref={this.hiddenInputRef}
                            id="chooseFileInput"
                            type="file"
                            hidden
                            accept="text/csv"
                            onChange={(event) => {
                                this.handleFileSelect(event.target.files[0])
                            }}
                        />
                        <Button
                            formAction="none"
                            onClick={() => {
                                this.hiddenInputRef.current.value = null;
                                this.hiddenInputRef.current.click();
                            }}
                        >
                            <SpaceBetween direction="horizontal" size="xs">
                                <Icon name="search" size="normal" variant="normal" />
                                Choose File
                            </SpaceBetween>
                        </Button>
                    </FormField>
                    {uploadedFile}
                    {previewContent}
                </SpaceBetween>
            </Form>
        );
    }
}
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import Home from "./components/Home";
import FlyerDetails from "./components/FlyerDetails";
import Create from "./components/Create";
import Search from "./components/Search";
import { JSON_HEADERS } from "./util/constants";

export default class AppRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.uid,
            permission: null,
            authenticated: true,
            flyers: []
        };
        this.getFlyers = this.getFlyers.bind(this);
    }

    componentDidMount() {
        // will be replaced with api calls upon api integration
        fetch(`permission.json`, {
            headers: JSON_HEADERS
        }).then(result => {
            return result.json();
        }).then(permission => this.setState({ permission: permission}));
    }

    getFlyers(homeOrSearch, searchParams="") {
        // will be replaced with api calls upon api integration
        fetch(`${homeOrSearch}Page${searchParams}.json`, {
            headers: JSON_HEADERS
        }).then(result => {
            return result.json();
        }).then(flyers => this.setState({ flyers: flyers}));
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route
                        exact path="/"
                        render={props => (
                            <Home {...props} {...this.state} getFlyers={this.getFlyers}/>
                        )}
                    />
                    <Route
                        exact path="/flyer/:id"
                        render={props => (
                            <FlyerDetails {...props} {...this.state}/>
                        )}
                    />
                    <Route
                        path="/create"
                        render={props => (
                            <Create {...props} {...this.state}/>
                        )}
                    />
                    <Route
                        path="/search"
                        render={props => (
                            <Search {...props} {...this.state} getFlyers={this.getFlyers}/>
                        )}
                    />
                    <Redirect from="*" to="/" />
                </Switch>
            </Router>
        );
    }
}

export const COLLECTION_PREFERENCES = {
    title: "Preferences",
    confirmLabel: "Confirm",
    cancelLabel: "Cancel",
    pageSizePreference: {
        title: "Select page size",
        options: [
            { value: 10, label: "10 promotions" },
            { value: 20, label: "20 promotions" }
        ]
    }
};

export const PAGINATION_LABELS = {
    nextPageLabel: "Next page",
    previousPageLabel: "Previous page",
    pageLabel: pageNumber =>
        `Page ${pageNumber} of all pages`
};
import React, { Component } from 'react';
import HomeTable from "./HomeTable";
import FlyerSearch from "./FlyerSearch";
import { Container, Header, SpaceBetween } from "@amzn/awsui-components-react/polaris";

export default class Home extends Component {
    componentDidMount() {
        this.props.getFlyers("home");
    }

    render() {
        return (
            <Container>
                <SpaceBetween direction="vertical" size="xs">
                    <Header
                        actions={
                            <FlyerSearch history={this.props.history}/>
                        }
                    >
                        {`FDGeT Home (signed in as ${this.props.user})`}
                    </Header>
                    <HomeTable
                        {...this.props}
                        title="Weekly Flyers"
                        create={this.props.permission === "admin" || this.props.permission === "superuser"}
                    />
                </SpaceBetween>
            </Container>
        )
    }
}
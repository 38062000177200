import React from 'react';
import { Auth } from 'aws-amplify';
import AppRouter from './AppRouter';

const App = () => {
    const [user, updateUser] = React.useState({userId: '', groups: []});

    React.useEffect(() => {
        console.log('checking user info...')
        //dispatch({type: SERVER_REQUEST_START})
        Auth.currentAuthenticatedUser()
            .then(cognitoUserData => {
                const idToken = cognitoUserData.getSignInUserSession().getIdToken();
                console.log("cognito payload");
                console.log(idToken.payload);
                const userId = idToken.payload['identities'][0].userId
                updateUser({userId: userId});
            })
            .catch(() => {
                console.log('No signed in user. sign in...')
                Auth.federatedSignIn({customProvider: 'AmazonFederate'})
                    .then(cred => {
                        console.log("cred: ");
                        console.log(cred);
                        return Auth.currentAuthenticatedUser();
                    })
                    .then(user => {
                        console.log(user)
                    })
                    .catch(e => {
                        console.log(e);
                    });
            });
    }, []);

    if (!user.userId) return (<div className="fa-3x"><i className="fa fa-spinner fa-spin"></i></div>)
    else {
        if (user && user.userId) {
            return (
                <div className='App'>
                    <AppRouter uid={user.userId}/>
                </div>
            )
        }
        else {
            return (<div>No user</div>);
        }
    }
}

export default App;
import React, { Component } from 'react';
import {
    Autosuggest,
    Box,
    Button,
    Input,
    FormField,
    Modal,
    Select,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ACTION_OPTIONS,
    ASSIGNEE_OPTIONS,
    REASON_OPTIONS 
} from "../util/configAssigneeSelectModal";

export default class AssigneeSelectModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flyerData: props.flyerData,
            visible: false,
            newAssignee: "",
            action: 0,
            reason: 0,
            comment: "",
            actionOptions: ACTION_OPTIONS,
            assigneeOptions: ASSIGNEE_OPTIONS,
            reasonOptions: REASON_OPTIONS
        };
    }

    render() {
        return (
            <div>
                <Button
                    onClick={() => {
                        this.setState({visible: true});
                    }}
                >
                    Reassign
                </Button>
                <Modal
                    onDismiss={() => {
                        this.setState({
                            visible: false,
                            newAssignee: "",
                            action: 0,
                            reason: 0,
                            comment: ""
                        });
                    }}
                    visible={this.state.visible}
                    closeAriaLabel="Close modal"
                    size="medium"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            visible: false,
                                            newAssignee: "",
                                            action: 0,
                                            reason: 0,
                                            comment: ""
                                        });
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={!(this.state.reason &&
                                                this.state.assigneeOptions.some(pair =>
                                                    pair.value === this.state.newAssignee) &&
                                                this.state.action)}
                                    onClick={() => {
                                        // TODO: send along data first
                                        // then clear modal
                                        // then refresh the page
                                        this.setState({
                                            visible: false,
                                            newAssignee: "",
                                            action: 0,
                                            reason: 0,
                                            comment: ""
                                        });
                                        this.props.history.push("/force-reload");
                                    }}
                                >
                                    Assign
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="Flyer Assignment"
                >
                    <SpaceBetween direction="vertical" size="xs">
                        <FormField
                            label="Action"
                        >
                            <Select
                                selectedOption={this.state.action}
                                onChange={({ detail }) =>
                                    this.setState({ action: detail.selectedOption })
                                }
                                options={this.state.actionOptions}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                        <FormField
                            label="Assignee"
                        >
                            <Autosuggest
                                onChange={({ detail }) =>
                                    this.setState({ newAssignee: detail.value })
                                }
                                value={this.state.newAssignee}
                                options={this.state.assigneeOptions}
                                enteredTextLabel={value => value}
                                ariaLabel="Autosuggest example with suggestions"
                                placeholder="Enter user"
                                empty="No matches found"
                            />
                        </FormField>
                        <FormField
                            label="Reason"
                        >
                            <Select
                                selectedOption={this.state.reason}
                                onChange={({ detail }) =>
                                    this.setState({ reason: detail.selectedOption })
                                }
                                options={this.state.reasonOptions}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                        <FormField
                            label="Comment (Optional)"
                        >
                            <Input
                                value={this.state.comment}
                                onChange={event =>
                                    this.setState({ comment: event.detail.value })
                                }
                            />
                        </FormField>
                    </SpaceBetween>
                </Modal>
            </div>
        );
    }
}

export const COLUMN_DEFINITIONS = [
    {
        id: "Rank",
        header: "Rank",
        cell: e => e.Rank,
        sortingField: "Rank"
    },
    {
        id: "Name",
        header: "Name",
        cell: e => e.Name,
        sortingField: "Name"
    },
    {
        id: "UPCASIN",
        header: "UPC/ASIN",
        cell: e => e.UPCASIN,
        sortingField: "UPCASIN"
    },
    {
        id: "Brand",
        header: "Brand",
        cell: e => e.Brand,
        sortingField: "Brand"
    },
    {
        id: "PromoTypeString",
        header: "Promotion Type",
        cell: e => e.PromoTypeString,
        sortingField: "PromoTypeString"
    },
    {
        id: "RegularPrice",
        header: "Regular Price",
        cell: e => e.RegularPrice,
        sortingField: "RegularPrice"
    },
    {
        id: "PromoPrice",
        header: "Promotion Price",
        cell: e => e.PromoPrice,
        sortingField: "PromoPrice"
    },
    {
        id: "StartDate",
        header: "StartDate",
        cell: e => e.StartDate,
        sortingField: "StartDate"
    },
    {
        id: "EndDate",
        header: "EndDate",
        cell: e => e.EndDate,
        sortingField: "EndDate"
    }
];
import React, { Component } from "react";
import {
    Button,
    Header,
    Icon,
    SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import DetailCollection from "./DetailCollection";
import ModifyCell from "./ModifyCell";
import cloneDeep from "lodash.clonedeep";
import { v4 as uuidv4 } from "uuid";
import { JSON_HEADERS } from "../util/constants";
import { COLUMN_DEFINITIONS } from "../util/configDetailTable";

export default class DetailTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flyerId: props.flyerId || null,
            preview: props.preview || false,
            originalDetails: props.details || [],
            modifiableDetails: [],
            editControlsEnabled: props.editControlsEnabled || false,
            editing: props.editing || false,
            metadata: props.metadata || null,
            error: null,
            editWatcher: props.editWatcher || ((editStatus) => {}),
            onConfirm: props.onConfirm || ((payload) => {}),
            detailIdToRow: {}
        };
        this.logFlyerModification = this.logFlyerModification.bind(this);
        this.mapDetailIdToRow = this.mapDetailIdToRow.bind(this);
    }

    componentDidMount() {
        if (!this.state.preview) {
            fetch("../flyerDetails.json", {
                headers: JSON_HEADERS
            }).then(result => {
                return result.json();
            }).then(data => {
                this.setState({
                    originalDetails: data,
                    modifiableDetails: cloneDeep(data),
                    detailIdToRow: this.mapDetailIdToRow(data)
                })
            });
        }
        else if (!this.state.metadata || this.state.originalDetails === []) {
            this.setState({ error: true });
        }
        else {
            this.setState({
                modifiableDetails: cloneDeep(this.state.originalDetails),
                detailIdToRow: this.mapDetailIdToRow(this.state.originalDetails)
            });
        }
    }

    mapDetailIdToRow(items) {
        let newDetailIdToRow = {};
        for (let i = 0; i < items.length; i++) {
            newDetailIdToRow[items[i].FlyerEntryID] = i;
        }
        return newDetailIdToRow;
    }

    logFlyerModification(operation, payload) {
        var modItems = cloneDeep(this.state.modifiableDetails);
        var mappingUpdate = null;

        if (operation === "add") {
            let newEntry = {
                FlyerEntryID: uuidv4(),
                Name: "",
                PromoTypeString: "",
                StartDate: "",
                EndDate: ""
            };
            modItems.push(newEntry);
            mappingUpdate = { detailIdToRow: this.mapDetailIdToRow(modItems) };
        }
        else if (operation === "delete") {
            let rowIndex = this.state.detailIdToRow[payload.id];
            modItems.splice(rowIndex, 1);
            mappingUpdate = { detailIdToRow: this.mapDetailIdToRow(modItems) };
        }
        else if (operation === "edit") {
            let rowIndex = this.state.detailIdToRow[payload.id];
            modItems[rowIndex][payload.attribute] = payload.value;
        }

        this.setState({
            modifiableDetails: modItems,
            ...mappingUpdate
        });
    }

    render() {
        if (this.state.error) {
            return <>Invalid Data for Flyer Creation</>;
        }

        let columnDefinitions = COLUMN_DEFINITIONS;

        if (this.state.editing) {
            let modifiedColumnDefinitions = cloneDeep(columnDefinitions);
            for (let i = 0; i < modifiedColumnDefinitions.length; i++) {
                let column = modifiedColumnDefinitions[i];
                column.cell = e => (
                    <ModifyCell
                        attribute={column.id}
                        value={e[column.id]}
                        id={e.FlyerEntryID}
                        logFlyerModification={this.logFlyerModification}
                    />
                );
            }

            let deleteColumnDefinition = {
                id: "DeleteEntry",
                header: "Delete",
                cell: e => (
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            this.logFlyerModification("delete", {"id": e.FlyerEntryID})
                        }}
                    >
                        -
                    </Button>
                )
            };

            modifiedColumnDefinitions.unshift(deleteColumnDefinition);
            columnDefinitions = modifiedColumnDefinitions;
        }

        var editButton = (this.state.editControlsEnabled && !this.state.editing) ? (
            <Button
                onClick={() => {
                    this.state.editWatcher(true);
                    this.setState({ editing: true })
                }}
                variant="primary"
            >
                <SpaceBetween direction="horizontal" size="xs">
                    <Icon name="edit" size="normal" variant="normal" />
                    Edit Flyer
                </SpaceBetween>
            </Button>
        ) : null;

        var editControls = (this.state.editControlsEnabled && this.state.editing) ? (
            <Header
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            onClick={() => {
                                let newDetailIdToRow = {};
                                for (let i = 0; i < this.state.originalDetails.length; i++) {
                                    newDetailIdToRow[this.state.originalDetails[i].FlyerEntryID] = i;
                                }
                                this.state.editWatcher(false);
                                this.setState({
                                    editing: false,
                                    modifiableDetails: cloneDeep(this.state.originalDetails),
                                    detailIdToRow: newDetailIdToRow
                                })
                            }}
                        >
                            Discard Changes
                        </Button>
                        <Button
                            onClick={event => {
                                event.preventDefault();
                                this.state.editWatcher(false);
                                this.state.onConfirm(this);
                            }}
                            variant="primary"
                        >
                            {this.state.preview ? "Save Changes" : "Submit Changes"}
                        </Button>
                    </SpaceBetween>
                }
            />
        ) : null;

        var editFooter = (this.state.editControlsEnabled && this.state.editing) ? (
            <Button onClick={(event) => {
                event.preventDefault();
                this.logFlyerModification("add", {});
            }}>
                <SpaceBetween direction="horizontal" size="xs">
                    <Icon name="add-plus"/>
                    Add Entry
                </SpaceBetween>
            </Button>
        ) : null;

        return (
            <DetailCollection
                details={this.state.modifiableDetails}
                columnDefinitions={columnDefinitions}
                editButton={editButton}
                editControls={editControls}
                editFooter={editFooter}
            />
        );
    }
}
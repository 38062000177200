import React, { useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Button,
    CollectionPreferences,
    Header,
    Icon,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter
} from '@amzn/awsui-components-react/polaris';
import AssigneeSelectModal from "./AssigneeSelectModal";
import EmptyState from "./EmptyState";
import {
    COLLECTION_PREFERENCES,
    COLUMN_DEFINITIONS,
    PAGINATION_LABELS
} from "../util/configHomeTable";

export default function HomeTable(props) {
    let permission = props.permission;
    let flyers = props.flyers;
    let create = props.create || false;
    let title = props.title;
    let user = props.user;

    let powerUser = permission === "admin" || permission === "superuser";

    const assigneeColumn = [{
        id: "Assignee",
        header: "Assignee",
        cell: e => (
            <SpaceBetween direction="horizontal" size="xs">
                {e.Assignee}
                {(powerUser || e.Assignee === user) ? (
                    <AssigneeSelectModal history={props.history} user={user} flyerData={e}/>
                ) : null}
            </SpaceBetween>
        )
    }];

    let columnDefinitions = COLUMN_DEFINITIONS.concat(assigneeColumn);

    const [preferences, setPreferences] = useState({
        pageSize: 10,
        visibleContent: columnDefinitions.map(column => {
            return column.id;
        })
    });

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        flyers,
        {
            filtering: {
                fields: preferences.visibleContent,
                empty: (
                    <EmptyState
                        title="No flyers"
                        subtitle="No flyer to display."
                        action={ (powerUser && create) ?
                            <Button onClick={() => {
                                props.history.push("/create");
                            }}>
                                Create flyer
                            </Button>
                        : null }
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="Could not find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {}
        }
    );

    let func = (selectEvent) => {
        props.history.push(`/flyer/${selectEvent.detail.selectedItems[0].FlyerID}`);
    };
    collectionProps.onSelectionChange = func;

    return (
        <Table
            {...collectionProps}
            selectionType="single"
            header={
                <SpaceBetween>
                    <Header
                        counter={` (${flyers.length})`}
                        actions={ (powerUser && create) ?
                            <Button variant="primary" onClick={() => {
                                props.history.push("/create");
                            }}>
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Icon name="edit" size="normal" variant="normal" />
                                    Create Flyer
                                </SpaceBetween>
                            </Button> :
                            null
                        }
                    >
                        {title}
                    </Header>
                    Select a flyer to view its details
                </SpaceBetween>
            }
            columnDefinitions={columnDefinitions}
            visibleColumns={preferences.visibleContent}
            items={items}
            pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_LABELS} />}
            filter={
                <TextFilter
                    {...filterProps}
                    filteringFields={preferences.visibleContent}
                    countText=<div>{`${filteredItemsCount} ${filteredItemsCount > 1 ? "matches" : "match"}`}</div>
                    filteringAriaLabel="Filter instances"
                    filteringPlaceholder="Search list"
                />
            }
            preferences={
                <CollectionPreferences id="collectionPrefs"
                    {...COLLECTION_PREFERENCES}
                    preferences={preferences}
                    onConfirm={({ detail }) => setPreferences(detail)}
                />
            }
        />
    );
}
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import {
    Button,
    ColumnLayout,
    Container,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import DetailTable from "./DetailTable";
import ActionMenu from "./ActionMenu";

export default class FlyerDetails extends Component {
    constructor(props) {
        super(props);
        let allowed = (flyers => {
            let idSet = {};
            flyers.forEach(flyer => idSet[flyer.FlyerID] = true);
            return idSet;
        })(props.flyers);
        this.state = {
            allowed: allowed[props.match.params.id] === true,
            permission: props.permission
        };
    }

    render() {
        // hardcoded for now, will be changed with api integration
        let metadata = (
            <SpaceBetween direction="vertical" size="xs">
                <span>ID: {this.props.match.params.id}</span>
                <span>Name: Flyer Name</span>
                <span>Description: A longer span of text to describe the purpose, contents, timeframe, etc
                    for the current flyer</span>
                <span>Period: 7/1/21 - 8/31/21</span>
            </SpaceBetween>
        );

        let permittedContent = (
            <Container>
                <Header
                    actions={
                        <Button onClick={() => {
                            this.props.history.push("/");
                        }}>
                            Return to Home
                        </Button>
                    }
                >
                    Flyer Details
                </Header>
                <SpaceBetween direction="vertical" size="xs">
                    <ColumnLayout columns={2}>
                        <Container
                            header={
                                <Header>
                                    Metadata:
                                </Header>
                            }
                        >
                            {metadata}
                        </Container>
                        <ActionMenu flyerId={this.props.match.params.id}/>
                    </ColumnLayout>
                    <DetailTable
                        flyerId={this.props.match.params.id}
                        editControlsEnabled={this.state.permission === "admin" || this.state.permission === "superuser"}
                    />
                </SpaceBetween>
            </Container>
        )

        let unknownPermission = null;

        let redirect = (
            <Redirect to="/"/>
        )

        return this.state.allowed === null ? unknownPermission :
            (this.state.allowed ? permittedContent : redirect);
    }
}
import React, { Component } from "react";
import { Input } from "@amzn/awsui-components-react/polaris";

export default class FlyerSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: !!props.value ? props.value : ""
        };
        this.hiddenInputRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const { value } = event.detail;
        this.setState({ value });
    }

    handleSubmit(event) {
        event.preventDefault();
        const { value } = this.state;
        if (value && value.trim()) {
            this.props.history.push(`/search?q=${encodeURIComponent(value)}`);
        }
    }

    render() {
        const { value } = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                <Input
                    onChange={this.handleChange}
                    value={value}
                    placeholder="Search previous flyers"
                    ref={this.hiddenInputRef}
                    type="search"
                />
            </form>
        );
    }
}

import React, {Component} from "react";
import {
    Autosuggest,
    Button,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween
} from "@amzn/awsui-components-react";
import {
    ACTION_OPTIONS,
    ASSIGNEE_OPTIONS,
    REASON_OPTIONS
} from "../util/configActionMenu";

export default class ActionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flyerId: props.flyerId,
            newAssignee: "",
            action: 0,
            reason: 0,
            comment: "",
            actionOptions: ACTION_OPTIONS,
            assigneeOptions: ASSIGNEE_OPTIONS,
            reasonOptions: REASON_OPTIONS
        };
        this.submitAction = this.submitAction.bind(this);
    }

    submitAction() {
        // Send action data to api using flyerId
    }

    render() {
        return (
            <Container
                header={
                    <Header>
                        Actions
                    </Header>
                }
                footer={
                    <Header
                        actions={
                            <Button
                                disabled={!(this.state.reason &&
                                    this.state.assigneeOptions.some(pair =>
                                        pair.value === this.state.newAssignee) &&
                                    this.state.action)}
                                variant="primary"
                                onClick={this.submitAction}
                            >
                                Submit
                            </Button>
                        }
                    />
                }
            >
                <SpaceBetween direction="vertical" size="xs">
                    <ColumnLayout columns={2}>
                        <FormField
                            label="Action"
                        >
                            <Select
                                selectedOption={this.state.action}
                                onChange={({detail}) =>
                                    this.setState({action: detail.selectedOption})
                                }
                                options={this.state.actionOptions}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                        <FormField
                            label="Assignee"
                        >
                            <Autosuggest
                                onChange={({detail}) =>
                                    this.setState({newAssignee: detail.value})
                                }
                                value={this.state.newAssignee}
                                options={this.state.assigneeOptions}
                                enteredTextLabel={value => value}
                                ariaLabel="Autosuggest example with suggestions"
                                placeholder="Enter user"
                                empty="No matches found"
                            />
                        </FormField>
                    </ColumnLayout>
                    <ColumnLayout columns={2}>
                        <FormField
                            label="Reason"
                        >
                            <Select
                                selectedOption={this.state.reason}
                                onChange={({detail}) =>
                                    this.setState({reason: detail.selectedOption})
                                }
                                options={this.state.reasonOptions}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                        <FormField
                            label="Comment (Optional)"
                        >
                            <Input
                                value={this.state.comment}
                                onChange={event =>
                                    this.setState({comment: event.detail.value})
                                }
                            />
                        </FormField>
                    </ColumnLayout>
                </SpaceBetween>
            </Container>
        );
    }
}
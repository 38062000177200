import React from 'react';
import {
    Button,
    Container,
    Header
} from "@amzn/awsui-components-react/polaris";
import FlyerCreationForm from "./FlyerCreationForm";
import { Redirect } from "react-router-dom";

export default function Create(props) {
    if (props.permission === null) {
        return null;
    }
    if (props.permission !== "admin" && props.permission !== "superuser") {
        return (
            <Redirect to="/"/>
        );
    }

    return (
        <Container>
            <Header
                actions={
                    <Button onClick={() => {
                        props.history.push("/");
                    }}>
                        Return to Home
                    </Button>
                }
            >
                Create Flyer
            </Header>
            <Container>
                <FlyerCreationForm user={props.user}/>
            </Container>
        </Container>
    );
}